import Card from '../components/Card'
import Waiting from '../components/Waiting'
import Checkout from '../components/Checkout'
import seasonData from '../api/data.js?v=134'
import { useState, useEffect } from 'react'


const Booking = () => {

  let open = new Date('2024-09-09');

  let url = window.location;

  let params = new URLSearchParams(url.search);
  let check = params.get('q');
  let paySuccess = params.get('success');
  let session = params.get('s');


  const [season, setSeason] = useState(seasonData.sort((a, b) => new Date(a.DateJS) - new Date(b.DateJS)));


  
  useEffect(()=> {

    if (session)
    {
      setSeason(seasonData.filter(a=> a.Title === session));
    }


  }, []);


  const [myList, setMyList] = useState([]);
  const [total, setTotal] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [waitingTitle, setWaitingTitle] = useState();
  const [checkout, setCheckout] = useState(false);
  const [payLink, setPayLink] = useState('');
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [giftLabel, setGiftLabel] = useState(0);

  const today = new Date();
  const minutes = today.getMinutes();


  useEffect(()=>{
    fetch('/sm1/places.php?v=' + minutes + '&c=1' , {cache: 'no-store'})
    .then((response) => response.json())
    .then((data) => {
      data.sort((a,b)=> new Date(a.Session_Date)-new Date(b.Session_Date));
      setAvailability(data);
      
      let giftFirst = () => {
        if (      data.filter(a=>  new Date(a.Session_Date) > today) )
        return 
        setGiftLabel(data.filter(a=>  new Date(a.Session_Date) > today));
      }
      
      giftFirst();      
      setLoading(true);

    });

    if (paySuccess)
    {
      setCheckout(true);
    }


  },[paySuccess]);

  // update total
  useEffect(()=>{

    let addUp = myList.reduce((total, obj) => obj.Cost + total,0);
    setTotal(addUp);

  },[myList])


  const getCheckout = async () => {
    const url = '/sm1/create-checkout-session.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myList)
      });

      if (response) {
        const responseData = await response.text();
        setPayLink(responseData); 
        setCheckout(true);
      } else {
        console.error('Error:', response.text());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };




  const addList = (s) => {

    setMyList([...myList, s]);

  }

  const removeList = (i) => {

    setMyList(myList.filter(item => item.ID !== i));

  }


    return (
         <>
      <div className='banner'></div>
      <section className='line'>
         <div className='intro'>
           <h2>Autumn season</h2>
            <p className='opening'>Explore the city in your writing!</p>
            <p>Our autumn season is an invitation to look up and around. Our programme explores the urban environment, buildings and architecture — and ways of living and finding creativity in the city.</p>
            <p>We're so delighted that artist Sophie Herxheimer and poet Pascale Petit will join us this season to offer workshops on the delights of domestic spaces and the relationship between nature and the urban spaces.</p>
            <p>All events this season take place via Zoom (UK time).</p>
         </div>
    </section>
    <section className='km'>
      <h3 className='center'>Become a member</h3>
      <div className='circles-wrap'>
        <div className='left'>
        <div className='circle-km-shadow'></div>
          <div className='circle-km-img img-0'></div>
        </div>
        <div className='right'>
          <p>Make the most of your mornings! Joining as a member gives you access to Write & Shine's entire programme as part of a monthly subscription.</p>
        </div>
      </div>
      <a href='https://membership.write-and-shine.com'>
        <button className='btncenter book'>Find out more</button>
      </a>
    </section>
    
{loading & availability.length > 0 && 
    <section>

{
check === 'window'  ?
  <section className='main'>
  
  { season.map((d,i)=>
    d.Booking &&
    <Card d={d} key={i} i={i} today={today} addList={addList} removeList={removeList} setWaiting={setWaiting} setWaitingTitle={setWaitingTitle} availability={availability} giftLabel={giftLabel} />
  )}
  </section>


: open > today ?
     <section className='main'>
        <h3 className='center'>Bookings open soon...</h3>
      </section>

:
    <section className='main'>
      { season.map((d,i)=>
         d.Booking &&
        <Card d={d} key={i} i={i} today={today} addList={addList} removeList={removeList} setWaiting={setWaiting} setWaitingTitle={setWaitingTitle} availability={availability} giftLabel={giftLabel} />
      )}
    </section>
}

<section className='main'>

<p className='center' style={{padding:'10px 30px 10px 30px'}}>Please note: No refunds available. Transfers to future workshops or courses may be possible with more than 48 hours' notice. Programme is subject to amendments and additions.</p>

</section>

</section>
}

  { myList.length > 0 &&
    <div className='basketbar'>
      <div className='top'>
      {
        myList.reverse().map((d,i)=>
          <div key={i}>
            {!d.Members ?
            <p>{d.Title + ' - £' + d.Cost}</p>
            :
            <p>{d.Title}</p>
            }
            <p>{d.Date}</p>
          </div>
        )
      }
      </div>
      <div className='bottom'>
        <p>Total = £{total}</p>
      </div>
     <button className='btnright checkout' onClick={()=>getCheckout()}>Checkout</button>
    </div>
   } 

   { waiting &&
   <>
   <div className='fixedback' onClick={()=>setWaiting(false)}></div>
   <Waiting session={waitingTitle} setWaiting={setWaiting} />
   </>
   }

  { checkout &&
   <>
   <div className='fixedback' onClick={()=>{ setCheckout(false); setPayLink('') }}></div>
   <Checkout total={total} setCheckout={setCheckout} payLink={payLink} setPayLink={setPayLink} myList={myList.reverse()} setMyList={setMyList} paySuccess={paySuccess} />
   </>
   }
   
   <section className='km'>
      <h3 className='center'>Give the gift of creativity!</h3>
      <div className='circles-wrap'>
        <div className='left'>
        <div className='circle-km-shadow'></div>
          <div className='circle-km-img img-1'></div>
        </div>
        <div className='right'>
          <p>Our peaceful morning workshops and inspiring online library are perfect gifts for those who want to commit to their writing, or simply want to add a little more creativity to their lives.</p>
        </div>
      </div>
      <a href='https://gifts.write-and-shine.com/'>
        <button className='btncenter book'>Find out more</button>
      </a>
    </section>
   </>

    )
}

export default Booking