// import './App.css';
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Booking from './pages/Booking'
import Membership from './pages/Membership'
import Gifts from './pages/Gifts'
import Salon from './pages/Salon'
import Notebook from './pages/Notebook'
import Reverse from './pages/Reverse'


function App() {
  return (
    <>
    <Header />
       <Booking />
    <Footer />
    </>
  );
}



export default App;
