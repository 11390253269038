const season = [
  {
    "ID": 0,
    "Title": "Arrival",
    "Date": "Tuesday 17 September (optional writing hour: 6.15-7.15am, workshop: 7.15-9am)",
    "DateJS": "2024-09-17",
    "Type": "Workshop",
    "Cost": 29,
    "PriceID": "price_1MiNBaHCrpbwIIdvDk1ekrbn",
    "Gift": "#",
    "Blurb": "Autumn is here. One season ends, another arrives. We'll write about the experience of arriving — or awaiting a loved one — and read poems and stories set in airports and train stations. As Trinidadian novelist Sam Selvon writes in 'The Lonely Londoners,' “the station is that sort of place where you have a soft feeling” watching “people crying goodbye and kissing welcome.” We'll consider how moments of arrival offer rich material for writing. This session will include a gentle, optional writing hour before the workshop begins.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 1,
    "Title": "Environments inhabit us",
    "Date": "Thursday 19 September (7.15-9am)",
    "DateJS": "2024-09-19",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMbuHCrpbwIIdvjpFxKUjj",
    "Gift": "#",
    "Blurb": "The autumn equinox occurs on 22 September. To mark this moment of balance between light and dark, we'll slow down, notice the golden light and the falling leaves. Filmmaker Agnes Varda says “People are made of places… I think environments inhabit us.” We'll tune into our own unique environments to help us then step into a new phase of the year.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 2,
    "Title": "Dream Homes",
    "Date": "Tuesday 24 September (7.15-9am)",
    "DateJS": "2024-09-24",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMg7HCrpbwIIdvCpLbXLcI",
    "Gift": "#",
    "Blurb": "Join us to roam around fictional buildings and consider how writers have imagined the city. We'll look at Italo Calvino's 'Invisible Cities,' a book of fifty-five fictional places and An Yu's uncanny, mysterious novel 'Ghost Music' set in an ancient house in Beijing. We'll also take inspiration from the work of feminist geographers and architects — and spend the morning writing about places filled with possibilities.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 3,
    "Title": "Read & Shine",
    "Date": "Thursday 26 September (7.15-9.30am). For Write & Shine members",
    "DateJS": "2024-09-26",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "#",
    "Gift": "#",
    "Blurb": "Reading in autumn is one of the season's great pleasures. So cosy! We invite you to put down your phones and pens, wrap a blanket around your shoulders, and bring a (city) book to enjoy as the light lifts — in the company of other readers. Expect to leave with a shared autumnal reading list!",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": true
  },
  {
    "ID": 4,
    "Title": "Furniture",
    "Date": "Friday 27 September (7.15-9am). Guest tutor: Sophie Herxheimer",
    "DateJS": "2024-09-27",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMmRHCrpbwIIdvLVxLw20M",
    "Gift": "#",
    "Blurb": "Find a chair you like to sit on and join poet and artist Sophie Herxheimer to contemplate the domestic space. Trick yourself into remembering long ago rooms and who, how, what, when you were in them. Stretch your imaginations into colour, texture and incident! Sophie's book 'Velkom to Inklandt' brings vividly to life the voice of her German Jewish Grent Muzzer and is filled with wonderful black-and-white papercuts of tables, teapots and other remembered furniture.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 5,
    "Title": "Street Portraits",
    "Date": "Monday 30 September (7.15-9am)",
    "DateJS": "2024-09-30",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMs4HCrpbwIIdvOylDdKmf",
    "Gift": "#",
    "Blurb": "We'll take inspiration from street photographers such as Henri Cartier-Bresson and his idea of 'the decisive moment' and Vivian Maier's striking portraits shot in American cities. We'll think about urban exploring and the value of noticing our surroundings. Can we as writers channel the street photographer's keen sense of observation to capture life as it happens — on the page?",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 6,
    "Title": "Nature and the City",
    "Date": "Tuesday 1 October (6.30-7.30pm) and Wednesday 2 October (7.15-9am). Guest speaker: Pascale Petit",
    "DateJS": "2024-10-02",
    "Type": "Workshop",
    "Cost": 29,
    "PriceID": "price_1MiMtrHCrpbwIIdvL52SVYda",
    "Gift": "#",
    "Blurb": "Join us for a two-part writing workshop including a very special evening with Pascale Petit whose stunning debut novel 'My Hummingbird Father' is set in Paris and a remote plateau deep in the Venezuelan Amazon. We'll think about nature and the city as locations, ideas and images. We'll meet again in the morning to continue writing inspired by the ways the wild takes root in the city.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 7,
    "Title": "Urban Geometry",
    "Date": "Tuesday 8 October (7.15-9am)",
    "DateJS": "2024-10-08",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMuyHCrpbwIIdvfmZCVuEZ",
    "Gift": "#",
    "Blurb": "A morning exploring the history and design of cities and places we know. We'll discuss the value of close observation in our writing. We'll write about waterways, monuments and ghost signs — the remnants of hand-painted adverts on buildings. Join us to marvel at the symmetry, line and colour of the urban environment.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 8,
    "Title": "TBC",
    "Date": "Thursday 10 October (7.15-9am)",
    "DateJS": "2024-10-10",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiMy2HCrpbwIIdvQiCCp0hv",
    "Gift": "#",
    "Blurb": "TBC GUEST TUTOR WENDY ERSKINE",
    "Booking": false,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 9,
    "Title": "Autumn Retreat",
    "Date": "Friday 11 October (7.15-9.30am)",
    "DateJS": "2024-10-11",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiN0PHCrpbwIIdvLdqF2McP",
    "Gift": "#",
    "Blurb": "A chance to pause and reflect on your writing this autumn — in the company of a friendly group of writers. Bring your work-in-progress, a (city) book to read or some ideas to explore. We'll write together at sunrise, beginning with a writing prompt and closing with an intention-setting exercise.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 10,
    "Title": "Lighted Window",
    "Date": "Tuesday 15 October (7.15-9am)",
    "DateJS": "2024-10-15",
    "Type": "Workshop",
    "Cost": 23,
    "PriceID": "price_1MiN1QHCrpbwIIdviZn55iYl",
    "Gift": "#",
    "Blurb": "“Some people see the season shift by observing the birds, but I'm attuned to a more urban kind of signalling,” writes Frances Ambler. “As dusk hovers, the city becomes a landscape of illumination, each lighted window telling a different story.” A cosy autumnal workshop on windows as enticing panoramas for passers-by, offering glimpses into other people's worlds. We'll look at artwork and literature that explores this idea.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  },
  {
    "ID": 11,
    "Title": "Stories from the City",
    "Date": "Thursday 17 October (7.15-10am)",
    "DateJS": "2024-10-17",
    "Type": "Retreat",
    "Cost": 29,
    "PriceID": "price_1PwUKwHCrpbwIIdvJUo8VBHO",
    "Gift": "#",
    "Blurb": "To close our season, we'll celebrate urban exploring and stories from the city! What does the city offer us as both a setting and theme? How does naming specific streets and locations bring writing to life? Does the city have a voice? We'll immerse ourselves in city music, fiction and poetry including by PJ Harvey and Frank O'Hara who use the built environment to shape, energise and inspire their work. Note this is a longer event ending at 10am.",
    "Booking": true,
    "New": false,
    "Midmorning": false,
    "Inperson": false,
    "Members": false
  }
 ]

export default season