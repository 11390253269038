import { IoIosClose } from 'react-icons/io'
import { useState, useEffect } from 'react'

const Waiting = ({ session, setWaiting }) => {

    const [wait, setWait] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [checked, setChecked] = useState(false); 


    const runWaiting = e => {

        e.preventDefault();

        if (address.length === 0){

            fetch("/sm1/waiting.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    session:session, name:name, email:email, member:checked}),
            })
            .then((res) => res.text())
            .then((data) => {
                setWait(true);
                window.location.href = "#msg";
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });

        }

    }


    useEffect(()=> {
       
        if (wait === true)
        {
        setEmail('');
        setName('');
        }


    },[wait])


    return (
        <>
            <div className='waitingpop'>


               <IoIosClose size={40} style={{color:'#797979', position:'absolute', top: '10px', right:'10px', cursor:'pointer'}} onClick={()=>setWaiting(false)}  />
               
                <h4 className='center'>Waiting list</h4>
                <p className='sub center'>Please enter your details and we'll let you know if places become available for {session}.</p>


                <form onSubmit={ runWaiting}> 

                    <input className='waiting' type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Your name' minLength='3'></input>

                    <input className='waiting' type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email' required></input>

                    <input className='waiting address' type='text' value={address} onChange={(e) => setAddress(e.target.value)} placeholder='Your address'></input>

                    <label className="container"><p>Please tick this box if you're a member</p>
                        <input type="checkbox" name='checkbox' onChange={ ()=> setChecked(!checked)} />
                        <span className="checkmark"></span>
                    </label>
                    
                    <button className='btncenter book' type='submit'>Submit</button>
                </form>

                <div id='msg' style={{marginTop:'30px'}}></div>

                { wait === true ?
                <div className='success-msg'>
                    <p className='center'>You're now on our waiting list!</p>
                    <a href='https://write-and-shine.com'><button className='btncenter book nomartop'>Back to homepage</button></a>    
                </div>
                :
                <div></div>
                }

            </div>
        </>
    )
}

export default Waiting