import { useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { AiOutlineGift } from 'react-icons/ai'

const Card = ({i, d, today, addList, removeList, setWaiting, setWaitingTitle, availability, giftLabel}) => {

    const [active, setActive] = useState(false);
    const [gifthover, setGifthover] = useState(false);

    return (
        <div className='item-wrap' key={ i }>
            <div className={`left item-img${i}`}>
                { d.New ?
                <div className='item-new'><p>New!</p></div>
                : d.Midmorning ?
                <div className='item-mid'><p>Mid-morning!</p></div>
                : d.Inperson ?
                <div className='item-mid'><p>In person!</p></div>
                :
                <div></div>
                }
            </div>
            <div className='right'>
            <h4>{d.Title}</h4>
            { !d.Members ?
            <p className='sub'>£{d.Cost + '. ' + d.Date}</p>
            :
            <p className='sub'>{d.Date}</p>
            }
            <p className='blurb'>{d.Blurb}</p>

            { today > new Date(d.DateJS).setUTCHours(9,59,59,999) ?
            <button className='btnright soldout'>Sold out</button>
        
            : parseInt(availability[i].Remaining) <= 0 ?
           // NOT d.ID
            <button className='btnright waiting' onClick={()=>{setWaiting(true); setWaitingTitle(d.Title)}}><IoIosAdd size={25} style={{color:'#51516b', position:'absolute', top: '12px', left:'15px'}} /> waiting list</button>
            
            : active ?
            
            <button className='selected btnright' onClick={()=>{removeList(d.ID); setActive(false)}}>Selected</button>

            : d.Members ?
            <button className='btnright members' onClick={()=>{addList(d); setActive(true)}}>Members only</button>
            
            :
            <>
            {
            d.ID === 100 // parseInt(giftLabel) 
            ?
            <div className='gifttipwrap'>
            <div className='gifttip'>Gift this session to a friend!</div>    
            </div>  

            : gifthover ?
            <div className='gifttipwrap'>
             <div className='gifttip'>Gift this session to a friend!</div>    
            </div>  
            
            :
            <></>
            }
            
            <a href={d.Gift}>
                <AiOutlineGift size={35} style={{color:'#51516b', position:'absolute', top: '10px', right:'15px', cursor:'pointer', display:'none'}} onMouseEnter={() => setGifthover(true)} onMouseLeave={() => setGifthover(false)}  />
            </a>

            <button className='book btnright' onClick={()=>{addList(d); setActive(true)}}>Book now</button>
            </>

            }
            </div>
        </div>

    )
}

export default Card